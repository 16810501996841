// src/pages/Projects.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';

interface Repository {
  name: string;
  description: string;
  html_url: string;
}

const Projects: React.FC = () => {
  const [repos, setRepos] = useState<Repository[]>([]);

  useEffect(() => {
    const fetchRepos = async () => {
      const response = await axios.get('https://api.github.com/users/bbkpr/repos');
      setRepos(response.data);
    };

    fetchRepos();
  }, []);

  return (
    <Container>
      <Row className="projects">
        <Col>
          <h2>Featured Projects</h2>
          {repos.map((repo) => (
            <div key={repo.name}>
              <h3>
                <a href={repo.html_url} target="_blank" rel="noopener noreferrer">
                  {repo.name}
                </a>
              </h3>
              <p>{repo.description}</p>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Projects;