// src/components/Header.tsx
import React from "react";
import { Navbar, Container, Stack } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Helmet } from "react-helmet";
import rpLogo from "../images/rp-logo.png";

const Header: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Robert Parker - Expert Software Engineer and Leader</title>
        <meta
          name="description"
          content="The professional portfolio of Robert Lawrence Parker, an expert Software Engineer and leader, specializing in TypeScript, React, and Node.js. Robert is currently available for hire."
        />
      </Helmet>
      <Navbar
        data-bs-theme="dark"
        bg="dark"
        expand="md"
        collapseOnSelect={true}
        sticky="top"
        variant="dark"
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <Stack direction="horizontal" gap={0}>
                <img id="brand-logo" src={rpLogo} alt="Robert Parker Logo" />
                <div className="ms-1 fw-bold">Robert Parker</div>
              </Stack>
            </Navbar.Brand>
          </LinkContainer>
          {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />*/}
          {/*<Navbar.Collapse id="basic-navbar-nav">*/}
          {/*  <Nav className="ms-auto">*/}
          {/*    <LinkContainer to="/">*/}
          {/*      <Nav.Link>Home</Nav.Link>*/}
          {/*    </LinkContainer>*/}
          {/*    <LinkContainer to="/projectgallery">*/}
          {/*      <Nav.Link>Project Highlights</Nav.Link>*/}
          {/*    </LinkContainer>*/}
          {/*  </Nav>*/}
          {/*</Navbar.Collapse>*/}
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
