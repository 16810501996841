// src/pages/ProjectDetails.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const ProjectDetails: React.FC = () => {
  const { projectName } = useParams<{ projectName: string }>();

  return (
    <Container>
      <Row>
        <Col>
          <h2>{projectName}</h2>
          <p>Project description goes here...</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectDetails;
