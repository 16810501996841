// src/components/ProjectGallery.tsx
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";

interface Repository {
  name: string;
  description: string;
  html_url: string;
}

const ProjectGallery: React.FC = () => {
  const [, setRepos] = useState<Repository[]>([]);

  useEffect(() => {
    const fetchRepos = async () => {
      const response = await axios.get(
        "https://api.github.com/users/bbkpr/repos",
      );
      setRepos(response.data);
    };

    fetchRepos();
  }, []);
  return (
    <Container>
      <Row className="project-gallery">
        <Col>
          <h2>Project Gallery</h2>
          {[...Array(5)].map((_, index) => (
            <Row
              key={index}
              className={`gallery-item ${index % 2 === 0 ? "even" : "odd"}`}
            >
              <Col md={6} className="code-excerpt">
                <code>
                  {`// Code Excerpt ${index + 1}
function exampleCode() {
console.log('This is a code excerpt');
}`}
                </code>
              </Col>
              <Col md={6} className="description">
                <h4>Description {index + 1}</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Nullam auctor, nisl eget ultricies tincidunt, quam sapien
                  ultricies nisl, vitae aliquam nisl nisl sit amet nisl.
                </p>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectGallery;
